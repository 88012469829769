import React, { useEffect, useState, useTransition, useRef } from "react";
import styles from "./pages.module.css";
import axios from "axios";
import ImageSlider from "../components/ImageSlider";
import gascorpImg from "../assets/images/home/gascorp.png";
import MapComponent from "../components/MapComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setDefaults,
  fromAddress,
  geocode,
  RequestType,
  fromLatLng,
  setRegion,
} from "react-geocode";
import { GOOGLE_MAPS_API_KEY, INSTA_ACCESS_TOKEN, SITE_URL } from "../config/config";
import { validateAddress } from "../config/validation";
import { fetchHome, fetchInstaFeed } from "../util/fetch";
import BudgetInfoModal from "../components/BudgetInfoModal";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { calculateDistance } from "../util/calculateDistance";
import DirectionSteps from "../components/DirectionSteps";
import { Helmet } from "react-helmet";
import { VIDEO_URL } from "../config/config";

const expiryDuration = 30*60;

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  const [secondIndex, setSecondIndex] = useState(null);
  const instaImages = useSelector((state) => state.home.instaImages);
  const [instaFeedImages, setInstaFeedImages] = useState([]);
  const [initialCords, setInitialCords] = useState({
    lat: -31.2532183,
    lng: 146.921099,
  });
  const [zoom, setZoom] = useState(6);
  const [markers, setMarkers] = useState([]);
  const [budgetMarker, setBudgetMarker] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [mapDisplay, setMapDisplay] = useState("markers");
  const [instructions, setInstructions] = useState(null);
  const [destination, setDestination] = useState(null);
  const [formData, setFormData] = useState({
    address: "",
    radius: "10",
    results: "10",
  });
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [infoType, setInfoType] = useState(" ");
  const [validationError, setValError] = useState({ message: null });
  const [numberOfSlides, setNumberOfSlides] = useState(4);
  const [numberOfSlidesLocal, setNumberOfSlidesLocal] = useState(1);
  const [numOfSlidesSupplier, setNumOfSlidesSupplier] = useState(5);
  const [storeLocations, setStoreLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [infoItem, setInfoItem] = useState({});
  const [map, setMap] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const setModal = (info, item) => {
    setInfoModalVisible(true);
    setInfoType(info);
    setInfoItem(item);
    document.body.classList.add("no_scroll", "modal_set");
    document.getElementById("address_div").classList.add("no_scroll");
  };
  const [homeData, setHomeData] = useState({
    bannerData: {},
    ourFuel: [],
    findNearestBudget: {},
    gascorp: {},
    careers: {},
    shopLocal: {},
    budgetBoost: {},
    directionImages: {},
    suppliers: [],
  });
  const [userLocation, setUserLocation] = useState(null);
  const videoRef = useRef(null);
  const updateNumOfSlides = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 767) {
      setNumberOfSlides(1);
      setNumOfSlidesSupplier(2);
    } else if (screenWidth <= 1024) {
      setNumberOfSlides(3);
      setNumOfSlidesSupplier(3);
    } else {
      setNumberOfSlides(4);
      setNumOfSlidesSupplier(5);
    }
  };
  useEffect(() => {
    updateNumOfSlides();
    window.addEventListener("resize", updateNumOfSlides);
    return () => {
      window.removeEventListener("resize", updateNumOfSlides);
    };
  }, []);
  useEffect(() => {
    if (addresses.length == 40) {
      const sortedAddresses = addresses.sort((a, b) => a.distance - b.distance);
      setBudgetMarker(sortedAddresses);
    }
  }, [addresses]);
  // initial marker coordinates
  useEffect(() => {
    const setPlaces = async () => {
      if (homeData.findNearestBudget.fnb_address) {
        homeData.findNearestBudget.fnb_address.map((item, index) => {
          fromAddress(item.address).then(({ results, status }) => {
            if (status === "OK") {
              const { lat, lng } = results[0].geometry.location;
              // console.log("dflknrkfmkfg", lat, lng);
              const distance = calculateDistance(
                initialCords.lat,
                initialCords.lng,
                lat,
                lng
              );
              const roundedDistance = Math.round(distance * 100) / 100;
              const formattedDistance = roundedDistance.toFixed(1);

              // setBudgetMarker((prev) => [
              //   ...prev,
              //   {
              //     title: item.title,
              //     address: item.address,
              //     callUs: item.callUs,
              //     openingHours: item.openingHours,
              //     serviceAvailable: item.serviceAvailable,
              //     productsAvailable: item.productsAvailable,
              //     distance: formattedDistance ? formattedDistance : "",
              //     latitude: lat,
              //     longitude: lng,
              //   },
              // ]);
              setAddresses((prev) => [
                ...prev,
                {
                  title: item.title,
                  address: item.address,
                  callUs: item.callUs,
                  openingHours: item.openingHours,
                  serviceAvailable: item.serviceAvailable,
                  productsAvailable: item.productsAvailable,
                  distance: formattedDistance ? formattedDistance : "",
                  latitude: lat,
                  longitude: lng,
                },
              ]);
              setStoreLocations((prev) => [
                ...prev,
                {
                  title: item.title,
                  address: item.address,
                  callUs: item.callUs,
                  openingHours: item.openingHours,
                  serviceAvailable: item.serviceAvailable,
                  productsAvailable: item.productsAvailable,
                  distance: formattedDistance ? formattedDistance : "",
                  latitude: lat,
                  longitude: lng,
                },
              ]);
              setMarkers((prevMarker) => [
                ...prevMarker,
                {
                  lat: lat,
                  lng: lng,
                  name: item.title,
                  vicinity: item.address,
                },
              ]);
            }
          });
        });
      }
    };
    setPlaces();
  }, [homeData.findNearestBudget]);
  useEffect(() => {
    if (formData.address === "") {
      const sortedLocations = storeLocations.sort(
        (a, b) => a.distance - b.distance
      );
      setBudgetMarker(sortedLocations);
      setMarkers([]);
      setZoom(6);
      setInitialCords({
        lat: -31.2532183,
        lng: 146.921099,
      });
      storeLocations.map((item, index) =>
        fromAddress(item.address).then(({ results, status }) => {
          if (status === "OK") {
            const { lat, lng } = results[0].geometry.location;
            setMarkers((prevMarker) => [
              ...prevMarker,
              {
                lat: lat,
                lng: lng,
                name: item.title,
                vicinity: item.address,
              },
            ]);
          }
        })
      );
      setNoResults(false);
    }
  }, [formData.address, storeLocations]);
  const handleForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    if (validateAddress(data.address).success == true) {
      setValError({ message: validateAddress(data.address).message });
    } else {
      setValError({ message: validateAddress(data.address).message });
    }
    if (data.address && validateAddress(data.address).success == true) {
      setMarkers([]);
      setBudgetMarker([]);
      setLoading(true);
      setNoResults(false);
      setInitialCords(null);
      setMapDisplay("markers");
      fromAddress(data.address)
        .then(({ results, status }) => {
          console.log(results)
          if (status === "OK") {
            var count = 0;
            {results.map((item) => (
              item.address_components.map((value) => {
                if(value.long_name == 'New South Wales'){
                  const { lat, lng } = item.geometry.location;
                  if (!map) {
                    return;
                  }
                  const nearbyStoresDistance = storeLocations
                    .map((place) => {
                      const distance = calculateDistance(
                        lat,
                        lng,
                        place.latitude,
                        place.longitude
                      );
                      return {
                        ...place,
                        distance,
                      };
                    })
                    .filter((place) => place.distance <= data.radius);
                  const nearbyStores = nearbyStoresDistance.sort(
                    (a, b) => a.distance - b.distance
                  );
                  // .slice(0, data.results);
                  if (nearbyStores.length > 0) {
                    nearbyStores.map((item, index) => {
                      setMarkers((prevMarker) => [
                        ...prevMarker,
                        {
                          lat: item.latitude,
                          lng: item.longitude,
                          name: item.title,
                          vicinity: item.address,
                        },
                      ]);
                      const distance = calculateDistance(
                        lat,
                        lng,
                        item.latitude,
                        item.longitude
                      );
                      const roundedDistance = Math.round(distance * 100) / 100; // Round to two decimal places
                      const formattedDistance = roundedDistance.toFixed(1);
                      setBudgetMarker((prev) => [
                        ...prev,
                        {
                          title: item.title,
                          address: item.address,
                          callUs: item.callUs,
                          openingHours: item.openingHours,
                          serviceAvailable: item.serviceAvailable,
                          productsAvailable: item.productsAvailable,
                          distance: formattedDistance ? formattedDistance : "",
                          latitude: item.latitude,
                          longitude: item.longitude,
                        },
                      ]);
                    });

                    // setBudgetMarker(nearbyStores);
                    setLoading(false);
                    setInitialCords({ lat: lat, lng: lng });
                    if (nearbyStores.length === 1) {
                      setZoom(12);
                    } else if (nearbyStores.length > 1 && nearbyStores.length <= 5) {
                      setZoom(9);
                    } else if (nearbyStores.length > 5 && nearbyStores <= 10) {
                      setZoom(8.5);
                    } else if (
                      nearbyStores.length > 10 &&
                      nearbyStores.length <= 25
                    ) {
                      setZoom(8);
                    } else if (
                      nearbyStores.length > 25 &&
                      nearbyStores.length <= 40
                    ) {
                      setZoom(7.5);
                    }
                    count++;
                  } else {
                    // setNoResults(true);
                    // setLoading(false);
                  }
                }
              })
            ))}
            if(count == 0){
              setNoResults(true);
              setLoading(false);
            }
            // const { lat, lng } = results[0].geometry.location;
            // if (!map) {
            //   return;
            // }
            // const nearbyStoresDistance = storeLocations
            //   .map((place) => {
            //     const distance = calculateDistance(
            //       lat,
            //       lng,
            //       place.latitude,
            //       place.longitude
            //     );
            //     return {
            //       ...place,
            //       distance,
            //     };
            //   })
            //   .filter((place) => place.distance <= data.radius);
            // const nearbyStores = nearbyStoresDistance.sort(
            //   (a, b) => a.distance - b.distance
            // );
            // // .slice(0, data.results);
            // if (nearbyStores.length > 0) {
            //   nearbyStores.map((item, index) => {
            //     setMarkers((prevMarker) => [
            //       ...prevMarker,
            //       {
            //         lat: item.latitude,
            //         lng: item.longitude,
            //         name: item.title,
            //         vicinity: item.address,
            //       },
            //     ]);
            //     const distance = calculateDistance(
            //       lat,
            //       lng,
            //       item.latitude,
            //       item.longitude
            //     );
            //     const roundedDistance = Math.round(distance * 100) / 100; // Round to two decimal places
            //     const formattedDistance = roundedDistance.toFixed(1);
            //     setBudgetMarker((prev) => [
            //       ...prev,
            //       {
            //         title: item.title,
            //         address: item.address,
            //         callUs: item.callUs,
            //         openingHours: item.openingHours,
            //         serviceAvailable: item.serviceAvailable,
            //         productsAvailable: item.productsAvailable,
            //         distance: formattedDistance ? formattedDistance : "",
            //         latitude: item.latitude,
            //         longitude: item.longitude,
            //       },
            //     ]);
            //   });

            //   // setBudgetMarker(nearbyStores);
            //   setLoading(false);
            //   setInitialCords({ lat: lat, lng: lng });
            //   if (nearbyStores.length === 1) {
            //     setZoom(12);
            //   } else if (nearbyStores.length > 1 && nearbyStores.length <= 5) {
            //     setZoom(9);
            //   } else if (nearbyStores.length > 5 && nearbyStores <= 10) {
            //     setZoom(8.5);
            //   } else if (
            //     nearbyStores.length > 10 &&
            //     nearbyStores.length <= 25
            //   ) {
            //     setZoom(8);
            //   } else if (
            //     nearbyStores.length > 25 &&
            //     nearbyStores.length <= 40
            //   ) {
            //     setZoom(7.5);
            //   }
            // } else {
            //   setNoResults(true);
            //   setLoading(false);
            // }
          }
        })
        .catch((error) => {
          setNoResults(true);
          setLoading(false);
        });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  // ****** CAROUSEL CHANGES *****
  const imageChangeFirst = (index) => {
    setFirstIndex(index);
    setSecondIndex(null);
  };
  const imageChangeSecond = (index) => {
    setSecondIndex(index);
    setFirstIndex(null);
  };
  //***Fetching Home Data***
  useEffect(() => {
    setBudgetMarker([]);
    document.title = "Home | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchHome();
      setHomeData({
        ...homeData,
        bannerData: response.data.banner,
        ourFuel: response.data.ourFuel,
        findNearestBudget: response.data.findNearestBudget,
        gascorp: response.data.boxBannerSectionOne,
        careers: response.data.boxBannerSectionSecond,
        shopLocal: response.data.boxBannerSectionThird,
        budgetBoost: response.data.boxBannerSectionFourth,
        directionImages: response.data.boxBannerSectionFifth,
        suppliers: response.data.supplier,
      });
    };
    fetchData();
    if (videoRef.current) {
      videoRef.current.play();
    }
    setDefaults({
      key: GOOGLE_MAPS_API_KEY,
      language: "en",
    });
    setRegion("au");
    // const fetchInsta = async () => {
    //   const response = await fetchInstaFeed();
    //   if (response !== null && response !== undefined) {
    //     setInstaFeedImages(response.data);
    //   }
    //   console.log("INSTA FEED : ",response)
    // };
    // fetchInsta();
  }, []);
  // *** INSTAGRAM FEED INTEGRATION ***
  // const [accessToken,setAccessToken] = useState(INSTA_ACCESS_TOKEN);
  const [accessToken,setAccessToken] = useState(localStorage.getItem("insta_access_token"));
  const [expiryTime,setExpiryTime] = useState(localStorage.getItem("token_expiry_time"));
  const fetchInsta = async () =>{
    console.log("TOKEN : ",accessToken)
    const response  = await axios({
      method:"get",
      url:`https://graph.instagram.com/me/media?fields=media_type,media_url,permalinks,thumbnail_url&limit=4&access_token=${accessToken}`
    });
    const feedResponse = response.data;
    if(response.status===200){
      console.log("RESPONSE INSTA : ",feedResponse.data)
      setInstaFeedImages(feedResponse.data);
    }
  }
  const refreshAccessToken=async (currentToken)=>{
    const response = await axios({
      method:"get",
      url:"https://graph.instagram.com/refresh_access_token",
      params:{
        grant_type: 'ig_refresh_token',
        access_token: currentToken,
      }
    })
    return response.data.access_token;
  }
  // *** check for token expiry ***
  const refreshToken = async()=>{
    const newToken = await refreshAccessToken(accessToken);
    const currentTime = Math.floor(Date.now()/1000);
    const newExpiryTime = currentTime+expiryTime;
    localStorage.setItem('insta_access_token', newToken);
    localStorage.setItem('token_expiry_time', newExpiryTime.toString());
    setAccessToken(newToken);
    setExpiryTime(newExpiryTime);
  }
  useEffect(()=>{
    fetchInsta();
  },[accessToken])
  useEffect(()=>{
    const checkAndRefreshToken = () => {
      const currentTime = Math.floor(Date.now() / 1000); 
      if (expiryTime - currentTime <= expiryDuration) {
        refreshToken();
      }
    };
    checkAndRefreshToken();
    
    // Set up an interval to check the token periodically
    const timer = setInterval(checkAndRefreshToken, expiryDuration * 1000);
    
    return () => clearInterval(timer); // Cleanup on component unmount
  },[accessToken,expiryTime])
  //***getting currentLocation of the user***
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log("Position :: ", position);
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
        // setInitialCords({ lat: latitude, lng: longitude });
      });
    }
    // localStorage.setItem("insta_access_token","");
  }, []);
  //*** Display Directions on Map ***
  const handleDirections = (latitude, longitude) => {
    setMapDisplay("directions");
    setDestination({ lat: latitude, lng: longitude });
  };

  const scrollTo = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);
  };

  const navigation = (id) => {
    navigate(id);
    scrollTo();
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Budget Petrol & Services | Gascorp - Quality Fuel & Convenience in NSW"
        />
        <meta
          property="og:description"
          content="Discover Budget Petrol, NSW's leading independent fuel provider. Enjoy high-quality fuel services at mechanical workshops, car washes, ute rentals, ATMs, convenience stores, and Fed and Full meals."
        />
        <meta property="og:url" content={`${SITE_URL}`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta
          property="article:modified_time"
          content="2024-05-22T06:01:40+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 minutes" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* Banner Section */}
      <div
        className={`${styles.banner_section} ${styles.banner_vid_sec}`}
        // style={{ backgroundImage: `url(${homeData.bannerData.banner_image})` }}
      >
        <div className={`${styles.video_section} ${styles.home_video_sec}`}>
          <video
            loop
            autoPlay
            muted
            playsInline
            ref={videoRef}
            className={styles.video_content}
          >
            <source src={`${VIDEO_URL}/home.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className={`${styles.container}  ${styles.container_vid_div}`}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {homeData.bannerData.banner_title}
            </h1>
          </div>
        </div>
      </div>
      {/* Find Nearest Place Section */}
      <div className={`${styles.find_nearest_section} ${styles.home_nearest}`}>
        <div className={styles.container}>
          <div className={styles.nearest_heading}>
            {homeData.findNearestBudget.fnb_title && (
              <h2 className={styles.nearest_txt}>
                {homeData.findNearestBudget.fnb_title.slice(0, 12)}{" "}
                <span>{homeData.findNearestBudget.fnb_title.slice(13)}</span>
              </h2>
            )}
          </div>
          <div className={styles.nearest_main}>
            <div className={styles.nearest_img_sec}>
              <div className={styles.img_left}>
                <img
                  src={homeData.findNearestBudget.fnb_image}
                  className={styles.nearest_img}
                  alt="Nearest Place"
                />
              </div>
              <div className={styles.img_right}>
                <MapComponent
                  cords={initialCords}
                  places={markers}
                  initialAddress={homeData.findNearestBudget}
                  setMap={setMap}
                  zoom={zoom}
                  mode={mapDisplay}
                  destination={destination}
                  setInstructions={setInstructions}
                />
              </div>
            </div>
            <div className={styles.nearest_form_div}>
              <form className={styles.nearest_form} onSubmit={handleForm}>
                <div className={styles.locations_div}>
                  <label className={`${styles.form_label}`}>Postcode / Suburbs</label>
                  <input
                    placeholder="Postcode / Suburbs"
                    type="text"
                    className={
                      validationError.message
                        ? `${styles.form_input} ${styles.input_error}`
                        : `${styles.form_input}`
                    } //styles.form_input
                    name="address"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                  />
                  {validationError.message && (
                    <p className={styles.error_txt}>
                      {validationError.message}
                    </p>
                  )}
                </div>
                <div className={styles.radius_div}>
                  <label className={styles.form_label}>Search Radius</label>
                  <select
                    className={styles.form_input}
                    // defaultValue={10}
                    value={formData.radius}
                    onChange={(e) =>
                      setFormData({ ...formData, radius: e.target.value })
                    }
                    name="radius"
                  >
                    <option value="10">10 km</option>
                    <option value="25">25 km</option>
                    <option value="50">50 km</option>
                    <option value="100">100 km</option>
                    <option value="200">200 km</option>
                    <option value="500">500 km</option>
                  </select>
                </div>
                {/* <div className={styles.result_div}>
                  <label className={styles.form_label}>Result</label> */}
                {/* <input
                    placeholder="10"
                    className={styles.form_input}
                    type="number"
                    value={formData.results}
                    onChange={(e) =>
                      setFormData({ ...formData, results: e.target.value })
                    }
                    name="results"
                  /> */}
                {/* <select
                    className={styles.form_input}
                    // defaultValue={10}
                    value={formData.results}
                    onChange={(e) =>
                      setFormData({ ...formData, results: e.target.value })
                    }
                    name="results"
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                  </select>
                </div> */}
                <div className={styles.submit_div}>
                  {/* <input type="submit" className={styles.submit_btn} /> */}
                  <button type="submit" className={styles.submit_btn}>
                    {" "}
                    Search
                  </button>
                </div>
              </form>
            </div>
            <div className={styles.address_section}>
              <div className={styles.nearest_address_sec} id="address_div">
                {loading && <Loader />}
                {noResults && (
                  <div className={styles.result_error}>
                    <p>No Results Found</p>
                  </div>
                )}
                {
                  budgetMarker.length > 0 &&
                    !loading &&
                    !noResults &&
                    mapDisplay === "markers" &&
                    budgetMarker.map((item, index) => (
                      <>
                        <div key={index} className={styles.nearest_place}>
                          <h3>{item.title}</h3>
                          <p className={styles.nearest_address}>
                            {item.address}
                          </p>
                          <p className={styles.nearest_phone}>
                            <span>Call Us:</span> {item.callUs}
                          </p>
                          <p className={styles.nearest_links}>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setModal("hours", item)}
                            >
                              Opening Hours
                            </a>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setModal("services", item)}
                            >
                              Services Available
                            </a>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setModal("products", item)}
                            >
                              Products Available
                            </a>
                          </p>
                          <div className={styles.distance_section}>
                            <div className={styles.distance_txt}>
                              <p>{item.distance} km</p>
                            </div>
                            <div className={styles.section_directions}>
                              <p>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    handleDirections(
                                      item.latitude,
                                      item.longitude
                                    )
                                  }
                                >
                                  Directions
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  // : homeData.findNearestBudget.fnb_address &&
                  //   !loading &&
                  //   !noResults &&
                  //   homeData.findNearestBudget.fnb_address.map(
                  //     (item, index) => (
                  //       <>
                  //         <div key={index} className={styles.nearest_place}>
                  //           <h3>{item.title}</h3>
                  //           <p className={styles.nearest_address}>
                  //             {item.address}
                  //           </p>
                  //           <p className={styles.nearest_phone}>
                  //             <span>Call Us:</span> {item.callUs}
                  //           </p>
                  //           <p className={styles.nearest_links}>
                  //             <a
                  //               href="javascript:void(0)"
                  //               onClick={() => setModal("hours", item)}
                  //             >
                  //               Opening Hours
                  //             </a>
                  //             <a
                  //               href="javascript:void(0)"
                  //               onClick={() => setModal("services", item)}
                  //             >
                  //               Services Available
                  //             </a>
                  //             <a
                  //               href="javascript:void(0)"
                  //               onClick={() => setModal("products", item)}
                  //             >
                  //               Products Available
                  //             </a>
                  //           </p>
                  //         </div>
                  //       </>
                  //     )
                  //   )
                }
                {mapDisplay === "directions" && instructions && (
                  <>
                    <DirectionSteps
                      instructions={instructions}
                      setMapDisplay={setMapDisplay}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Gascorp Section */}
      <div className={`${styles.gascorp_section} ${styles.gascorp_up}`}>
        <div className={styles.container}>
          <div className={styles.gascorp_header}>
            <div className={styles.gascorp_header_left}>
              <div className={styles.image_area}>
                <img
                  src={homeData.gascorp.bbs_image}
                  className={styles.gascorp_logo}
                  alt={homeData.gascorp.bbs_title}
                />
                <img
                  src={gascorpImg}
                  className={styles.gascorp_logo}
                  alt="GasCorp"
                />
              </div>
              {/* <div className={styles.read_more}>
                {homeData.gascorp.bbs_content && (
                  <p>{homeData.gascorp.bbs_content.slice(603, 732)}</p>
                )}
                <button
                  className={styles.read_more_btn}
                  onClick={() => navigation("our-story")}
                >
                  Read More
                </button>
              </div> */}
            </div>
            <div className={styles.gascorp_header_right}>
              {homeData.gascorp.bbs_content && (
                <>
                  <p className={styles.gascorp_head_txt}>
                    {homeData.gascorp.bbs_content.slice(3, 535)}
                    <br />
                    <br />
                    <span>{homeData.gascorp.bbs_content.slice(544, 594)}</span>
                  </p>
                  <div className={styles.read_more}>
                    {homeData.gascorp.bbs_content && (
                      <p>{homeData.gascorp.bbs_content.slice(603, 732)}</p>
                    )}
                    <button
                      className={styles.read_more_btn}
                      onClick={() => navigation("our-story")}
                    >
                      Read More
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.gascorp_gallery}>
            {homeData.gascorp.bbs_box && (
              <>
                <div className={styles.gallery_img1}>
                  <img
                    src={homeData.gascorp.bbs_box[0].image}
                    alt={homeData.gascorp.bbs_box[0].title}
                  />
                </div>
                <div className={styles.gallery_img2}>
                  <img
                    src={homeData.gascorp.bbs_box[1].image}
                    alt={homeData.gascorp.bbs_box[1].title}
                  />
                </div>
                <div
                  className={`${styles.gallerytitle_img3} ${styles.gallery_text}`}
                >
                  {homeData.gascorp.bbs_box[2].title}
                </div>
                <div className={styles.gallery_img3}>
                  <img
                    src={homeData.gascorp.bbs_box[2].image}
                    alt={homeData.gascorp.bbs_box[2].title}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Our Fuel Section */}
      <div className={`${styles.our_fuel_section} ${styles.fuels_main_sec}`}>
        <div className={styles.container}>
          <div className={styles.fuel_heading}>
            <h2 className={styles.fuel_txt}>
              Our <span>Fuels</span>
            </h2>
          </div>
          {/* OUR FUEL SLIDER */}
          <div className={styles.slider_wrap}>
            <div className={`${styles.slider} ${styles.slider_home_mobile}`}>
              {homeData.ourFuel.length > 0 && (
                <ImageSlider
                  slides={homeData.ourFuel}
                  setCurrentIndex={setCurrentIndex}
                  numberOfSlides={numberOfSlides}
                  sliderType="OurFuel"
                  currentIndex={currentIndex}
                />
              )}
            </div>
            {homeData.ourFuel.length > 0 && (
              <div
                className={`${styles.slider_txt_sec} ${styles.slider_txt_mobile}`}
              >
                {currentIndex === 0 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_purple}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[7].ourfuels_content,
                    }}
                  ></p>
                )}
                {currentIndex === 1 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_yellow}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[6].ourfuels_content,
                    }}
                  ></p>
                )}
                {currentIndex === 2 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_red}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[5].ourfuels_content,
                    }}
                  ></p>
                )}
                {currentIndex === 3 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_green}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[4].ourfuels_content,
                    }}
                  ></p>
                )}
                {currentIndex === 4 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_black}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[3].ourfuels_content,
                    }}
                  ></p>
                )}
                {currentIndex === 5 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_black_premium}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[2].ourfuels_content,
                    }}
                  ></p>
                )}
                {currentIndex === 6 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_navy}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[1].ourfuels_content,
                    }}
                  ></p>
                )}
                {currentIndex === 7 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_blue}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[0].ourfuels_content,
                    }}
                  ></p>
                )}
              </div>
            )}
            <div
              className={`${styles.fuel_slider_home} ${styles.slider_first}`}
            >
              {homeData.ourFuel
                .slice()
                .reverse()
                .map(
                  (item, index) =>
                    index <= 3 && (
                      <div
                        className={`${styles.img_wrap}`}
                        // onClick={()=>sliderChange(index)}
                      >
                        <img
                          src={item.ourfuels_image}
                          className={styles.fuel_image}
                          alt={item.ourfuels_title}
                          onClick={() => imageChangeFirst(index)}
                          // onMouseEnter={()=>imageChangeFirst(index)}
                          // onMouseLeave={()=>imageChangeFirst(null)}
                        />
                      </div>
                    )
                )}
            </div>
            {homeData.ourFuel.length > 0 && firstIndex !== null && (
              <div
                className={`${styles.slider_txt_sec} ${styles.slider_txt_desktop}`}
              >
                {firstIndex === 0 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_purple}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[7].ourfuels_content,
                    }}
                  ></p>
                )}
                {firstIndex === 1 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_yellow}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[6].ourfuels_content,
                    }}
                  ></p>
                )}
                {firstIndex === 2 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_red}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[5].ourfuels_content,
                    }}
                  ></p>
                )}
                {firstIndex === 3 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_green}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[4].ourfuels_content,
                    }}
                  ></p>
                )}
              </div>
            )}
            <div
              className={`${styles.fuel_slider_home} ${styles.slider_second} ${
                firstIndex == null && styles.slider_second_gap
              }`}
            >
              {homeData.ourFuel
                .slice()
                .reverse()
                .map(
                  (item, index) =>
                    index >= 4 && (
                      <div
                        className={`${styles.img_wrap}`}
                        // onClick={()=>sliderChange(index)}
                      >
                        <img
                          src={item.ourfuels_image}
                          className={styles.fuel_image}
                          alt={item.ourfuels_title}
                          onClick={() => imageChangeSecond(index)}
                          // onMouseEnter={()=>imageChangeSecond(index)}
                          // onMouseLeave={()=>imageChangeSecond(null)}
                        />
                      </div>
                    )
                )}
            </div>
            {homeData.ourFuel.length > 0 && secondIndex !== null && (
              <div
                className={`${styles.slider_txt_sec} ${styles.slider_txt_desktop}`}
              >
                {secondIndex === 4 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_black}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[3].ourfuels_content,
                    }}
                  ></p>
                )}
                {secondIndex === 5 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_black_premium}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[2].ourfuels_content,
                    }}
                  ></p>
                )}
                {secondIndex === 6 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_navy}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[1].ourfuels_content,
                    }}
                  ></p>
                )}
                {secondIndex === 7 && (
                  <p
                    className={`${styles.slider_txt} ${styles.txt_blue}`}
                    dangerouslySetInnerHTML={{
                      __html: homeData.ourFuel[0].ourfuels_content,
                    }}
                  ></p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Shop Local Section */}
      <div
        className={`${styles.shop_local_section} ${styles.update_shop_local_section}`}
      >
        <div className={styles.container}>
          <div className={styles.shop_local}>
            <div className={styles.shop_local_left}>
              {homeData.shopLocal.bbs_title && (
                <h2 className={styles.shop_local_heading}>
                  {homeData.shopLocal.bbs_title.slice(0, 10)}{" "}
                  <span>{homeData.shopLocal.bbs_title.slice(11)}</span>
                </h2>
              )}
              {homeData.shopLocal.bbs_image && (
                // <img
                //   src={homeData.shopLocal.bbs_image}
                //   alt="Shop Local"
                //   className={styles.mobile_view}
                // />
                <div className={styles.mobile_view}>
                  <ImageSlider
                    slides={homeData.shopLocal.bbs_box}
                    setCurrentIndex={setCurrentIndex}
                    numberOfSlides={numberOfSlidesLocal}
                    sliderType="ShopLocalMobile"
                  />
                </div>
              )}
              <h3 className={styles.shopLocal_second}>
                <span> Experience Convenience at Budget Petrol! </span>
              </h3>
              {homeData.shopLocal.bbs_content && (
                <p
                  className={styles.shop_local_txt}
                  dangerouslySetInnerHTML={{
                    __html: homeData.shopLocal.bbs_content,
                  }}
                >
                  {/* {homeData.shopLocal.bbs_content.slice(3, 250)} */}
                </p>
              )}
              <button
                className={styles.shop_local_btn}
                onClick={() => navigation("convenience")}
              >
                Know More
              </button>
            </div>
            {homeData.shopLocal.bbs_image && (
              // <div className={styles.our_story_img}>
              //   <img
              //     src={homeData.shopLocal.bbs_image}
              //     alt="Shop Local"
              //     className={styles.desktop_view}
              //   />
              <div className={styles.desktop_view}>
                <ImageSlider
                  slides={homeData.shopLocal.bbs_box}
                  setCurrentIndex={setCurrentIndex}
                  numberOfSlides={numberOfSlidesLocal}
                  sliderType="ShopLocalDesktop"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Suppliers Section */}
      {/* <div className={styles.supplier_section}>
          <div className={styles.container}>
            <div className={styles.supplier_heading}>
              <h2 className={styles.supplier_heading_txt}>Suppliers</h2>
            </div>
            <div className={styles.supplier_slider}>
              {homeData.suppliers.length > 0 && (
                <ImageSlider
                  slides={homeData.suppliers}
                  setCurrentIndex={setCurrentIndex}
                  numberOfSlides={numOfSlidesSupplier}
                  sliderType="Supplier"
                />
              )}
            </div>
          </div>
        </div> */}
      <div
        className={`${styles.supplier_page} ${styles.supplier_page_update} ${styles.supplier_update_up}`}
      >
        <div className={styles.container}>
          <div className={styles.up_main_heading}>
            <h2 className={styles.upper_main_heading}>Suppliers</h2>
          </div>
          <div className={styles.supplier_gallery}>
            {homeData.suppliers.length > 0 &&
              homeData.suppliers
                .slice()
                .reverse()
                .map((image, index) => (
                  <div className={styles.image_wrap}>
                    {" "}
                    <a href={`${image.supplier_link}`} target="_blank">
                      <img src={image.supplier_image} />
                    </a>
                  </div>
                ))}
          </div>
        </div>
      </div>
      {/* Career Section */}
      <div
        className={styles.career_section}
        style={{ backgroundImage: `url(${homeData.careers.bbs_image})` }}
      >
        <div className={styles.container}>
          <div className={styles.career_heading}>
            {homeData.careers.bbs_title && (
              <h2 className={styles.career_heading_txt}>
                {homeData.careers.bbs_title.slice(0, 10)}{" "}
                <span>{homeData.careers.bbs_title.slice(11)}</span>
              </h2>
            )}
            {homeData.careers.bbs_content && (
              <p
                className={styles.career_txt}
                // dangerouslySetInnerHTML={{
                //   __html: homeData.careers.bbs_content,
                // }}
              >
                {homeData.careers.bbs_content.slice(63, 116)}
                <sup>{homeData.careers.bbs_content.slice(116, 118)}</sup>
                {homeData.careers.bbs_content.slice(118, 173)}
                <sup>{homeData.careers.bbs_content.slice(173, 174)}</sup>{" "}
                {homeData.careers.bbs_content.slice(175, 193)}
                <a href="contact-us">
                  {homeData.careers.bbs_content.slice(214, 219)}
                </a>
              </p>
            )}
            {/* <a
              href="https://www.seek.com.au/budget-petrol-jobs?jobId=75852737&type=standout"
              target="_blank"
            >
              <button
                className={styles.career_btn}
                // onClick={() => navigation("employment")}
              >
                View Opportunities
              </button>
            </a> */}
            <button
              className={styles.career_btn}
              onClick={() => navigation("contact-us")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      {/* IMAGE SECTION */}
      <div className={styles.card_img_sec}>
        <div className={styles.container}>
          <div className={styles.shop_local_images}>
            {homeData.directionImages.bbs_box && (
              <>
                <div
                  className={styles.shop_img_left}
                  // onClick={() => navigation("fuel-1-card")}
                >
                  {/* <NavLink onClick={() => scrollTo()}> */}
                  <img
                    src={homeData.directionImages.bbs_box[0].image}
                    alt="Gift Card"
                  />
                  <p
                    className={styles.shop_img_txt}
                    // onClick={() => navigate("fuel-cards")}
                  >
                    {homeData.directionImages.bbs_box[0].title}
                  </p>
                  {/* </NavLink> */}
                </div>
                <div
                  className={styles.shop_img_right}
                  onClick={() => navigation("community")}
                >
                  <NavLink onClick={() => scrollTo()}>
                    <img
                      src={homeData.directionImages.bbs_box[1].image}
                      alt="Spot US"
                    />
                    <p
                      className={styles.shop_img_txt}
                      // onClick={() => navigate("community")}
                    >
                      {homeData.directionImages.bbs_box[1].title}
                    </p>
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Budget Boost Section */}
      <div className={styles.budget_boost_section}>
        <div className={styles.container}>
          <div className={styles.boost_royalty_left}>
            {homeData.budgetBoost.bbs_title && (
              <h2>{homeData.budgetBoost.bbs_title}</h2>
            )}
            {homeData.budgetBoost.bbs_image && (
              <img
                src={homeData.budgetBoost.bbs_image}
                alt="Boost Treat"
                className={styles.mobile_view}
              />
            )}
            <button onClick={() => navigation("budget-boost")}>
              Express Your Interest
            </button>{" "}
          </div>
          <div className={styles.boost_royalty_right}>
            {homeData.budgetBoost.bbs_image && (
              <img
                src={homeData.budgetBoost.bbs_image}
                alt="Boost Treat"
                className={styles.desktop_view}
              />
            )}
          </div>
        </div>
      </div>
      {/* Insta Gallery Section */}
      <div className={styles.insta_gallery_section}>
        <div className={styles.container}>
          <div className={styles.insta_heading}>
            <h2 className={styles.insta_heading_txt}>Instagram Gallery</h2>
          </div>
          <div className={styles.insta_gallery}>
            {instaFeedImages.length > 0
              ? instaFeedImages.map((image, index) => (
                  <div className={styles.insta_img}>
                    <a
                      href="https://www.instagram.com/budgetpetrol/"
                      target="_blank"
                    >
                      <img
                        src={image.media_type==="VIDEO" ? image.thumbnail_url :image.media_url}
                        className={styles.gallery_img}
                        key={index}
                        alt="Gallery"
                      />
                    </a>
                  </div>
                ))
              : instaImages.map((image, index) => (
                  <div className={styles.insta_img}>
                    <a
                      href="https://www.instagram.com/budgetpetrol/"
                      target="_blank"
                    >
                      <img
                        src={image}
                        className={styles.gallery_img}
                        key={index}
                        alt="Gallery"
                      />
                    </a>
                  </div>
                ))}
            {/* {instaImages.map((image, index) => (
              <div className={styles.insta_img}>
                <a
                  href="https://www.instagram.com/budgetpetrol/"
                  target="_blank"
                >
                  <img
                    src={image}
                    className={styles.gallery_img}
                    key={index}
                    alt="Gallery"
                  />
                </a>
              </div>
            ))} */}
          </div>
          <div className={styles.insta_follow}>
            <p className={styles.insta_follow_btn}>
              <a href="https://www.instagram.com/budgetpetrol" target="_blank">
                <button>Follow Us On Instagram</button>
              </a>
            </p>
          </div>
        </div>
      </div>
      <BudgetInfoModal
        modalVisible={infoModalVisible}
        setModalVisible={setInfoModalVisible}
        item={infoItem}
        infoType={infoType}
      />
    </>
  );
};

export default React.memo(HomePage);
